import { IconSize } from '@leon-hub/icons';

import type { SportlineNavigationIcon } from 'web/src/modules/sportline/components/navigation-indicator/types';
import type { VirtualSportFilterOptionDisplayConfig } from 'web/src/modules/sportline/submodules/virtual-sport/types';
import { SportlineNavigationItemType } from 'web/src/modules/sportline/enums/navigation';

export function displayConfigToIconConfig(
  displayConfig?: VirtualSportFilterOptionDisplayConfig,
): Maybe<SportlineNavigationIcon> {
  if (!displayConfig) { return null; }

  if (displayConfig.sportFamily) {
    return {
      type: SportlineNavigationItemType.SportIcon,
      props: {
        sportName: displayConfig.sportFamily,
        size: IconSize.SIZE_16,
      },
    };
  }

  if (displayConfig.icon) {
    return {
      type: SportlineNavigationItemType.Icon,
      props: {
        name: displayConfig.icon,
        size: displayConfig.highlighted ? IconSize.SIZE_28 : IconSize.SIZE_16,
      },
    };
  }

  return null;
}
