<script setup lang="ts">
import { onBeforeUnmount, toRef } from 'vue';

import { VLogoLoader } from '@components/loader';

import type { SlipMappingKey } from 'web/src/modules/framed-app/components/VirtualSportFramedWidget/types';
import { VLazyIframe as VIframe } from 'web/src/components/Iframe';

import { useVirtualSportWidget } from './useVirtualSportWidget';

interface VirtualSportWidgetProps {
  clientId: string;
  iframeUrl: string;
  providerUrl: string;
  selectedVirtualSport: Maybe<string>;
  selectedBetsMappings?: SlipMappingKey[];
  stylable?: boolean;
  internalWidgetLoaded?: boolean;
}

interface VirtualSportWidgetEmits {
  (e: 'widget-loaded', value: boolean): void;
}

const props = defineProps<VirtualSportWidgetProps>();
const emit = defineEmits<VirtualSportWidgetEmits>();

const clientId = toRef(props, 'clientId');
const iframeUrl = toRef(props, 'iframeUrl');
const providerUrl = toRef(props, 'providerUrl');
const selectedVirtualSport = toRef(props, 'selectedVirtualSport');
const selectedBetsMappings = toRef(props, 'selectedBetsMappings', []);
const stylable = toRef(props, 'stylable', false);
const internalWidgetLoaded = toRef(props, 'internalWidgetLoaded', false);

const {
  iframeName,
  frame,
  iframeWidgetSrc,
  additionalQueryStringParameters,
  onIFrameMounted,
  releaseWidget,
} = useVirtualSportWidget({
  clientId,
  iframeUrl,
  providerUrl,
  selectedVirtualSport,
  selectedBetsMappings,
  stylable,
}, {
  widgetLoaded(value: boolean): void {
    emit('widget-loaded', value);
  },
});

onBeforeUnmount(releaseWidget);
</script>

<template>
  <div v-auto-id="'VirtualSportWidget'" :class="$style['virtual-sport-widget']">
    <div
      v-if="!internalWidgetLoaded"
      :class="$style['virtual-sport-widget__loader']"
    >
      <VLogoLoader :class="$style['virtual-sport-widget__loader-image']" />
    </div>

    <VIframe
      v-if="iframeWidgetSrc"
      v-show="internalWidgetLoaded"
      ref="frame"
      :class="$style['virtual-sport-widget-frame']"
      :src="iframeWidgetSrc"
      full-width
      :name="iframeName"
      :additional-query-string-parameters="additionalQueryStringParameters"
      @vue:mounted="onIFrameMounted"
    />
  </div>
</template>

<style module lang="scss">
.virtual-sport-widget {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  min-height: 100vh;

  &__loader {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 453px;
    padding: 50px 0;
    background: var(--Layer0);
  }

  &__loader-image {
    max-width: 255px;
  }
}

.virtual-sport-widget-frame {
  min-height: 100vh;
}
</style>
