import type { Ref } from 'vue';
import {
  computed,
  ref,
  toRef,
  watch,
} from 'vue';

import { PostMessageBus } from '@leon-hub/postmessage-bus';

import { useI18nStore } from '@core/i18n';
import { useTheme } from '@core/theme';

import type { VIframeRef } from 'web/src/components/Iframe/VIframe/types';
import type {
  SlipMappingKey,
  VirtualSportChangedBets,
  VirtualSportState,
} from 'web/src/modules/framed-app/components/VirtualSportFramedWidget/types';
import type { IFrameWidgetName } from 'web/src/modules/framed-app/types';
import { requireContentWindowByIFrameName } from 'web/src/components/Iframe/VIframe/utils';
import {
  VirtualSportFramedWidgetProperties,
} from 'web/src/modules/framed-app/components/VirtualSportFramedWidget/enums';
import {
  VirtualSportWidgetPostMessageBusInitiator,
  VirtualSportWidgetPostMessageEvent,
} from 'web/src/modules/framed-app/components/VirtualSportFramedWidget/utils';
import { virtualSportIframeWidgetName } from 'web/src/modules/framed-app/constants';
import { getSearchParameterValue } from 'web/src/modules/framed-app/utils';
import { useVirtualSportStore } from 'web/src/modules/sportline/submodules/virtual-sport/store';
import { useFramedWidgetUrl } from 'web/src/modules/widgets/composables/useFramedWidgetUrl';

interface UseVirtualSportWidgetProps {
  clientId: Ref<string>;
  iframeUrl: Ref<string>;
  providerUrl: Ref<string>;
  selectedVirtualSport: Ref<Maybe<string>>;
  selectedBetsMappings: Ref<SlipMappingKey[]>;
  stylable: Ref<boolean>;
}

interface UseVirtualSportWidgetEmits {
  widgetLoaded(value: boolean): void;
}

interface UseVirtualSportWidgetComposable {
  iframeName: IFrameWidgetName;
  frame: Ref<Optional<VIframeRef>>;
  iframeWidgetSrc: Ref<string | undefined>;
  additionalQueryStringParameters: Ref<Record<string, string>[]>;
  onIFrameMounted(): void;
  releaseWidget(): void;
}

export function useVirtualSportWidget(
  props: UseVirtualSportWidgetProps,
  emit: UseVirtualSportWidgetEmits,
): UseVirtualSportWidgetComposable {
  const {
    clientId,

    providerUrl,
    selectedVirtualSport,
    selectedBetsMappings,
    stylable,
  } = props;

  const { theme } = useTheme();
  const lang = toRef(useI18nStore(), 'lang');
  const { addToSlip, removeFromSlip } = useVirtualSportStore();

  const iframeName = virtualSportIframeWidgetName;
  let postMessageBus: Maybe<Readonly<PostMessageBus>> = null;

  const frame = ref<Optional<VIframeRef>>();

  const iframeWidgetSrc = useFramedWidgetUrl(iframeName);
  const additionalQueryStringParameters = computed<Record<string, string>[]>(() => ([
    { [VirtualSportFramedWidgetProperties.IsDesktop as string]: process.env.VUE_APP_LAYOUT_DESKTOP ? '1' : '0' },
    { [VirtualSportFramedWidgetProperties.Lang as string]: lang.value },
    { [VirtualSportFramedWidgetProperties.Theme as string]: theme.value.toLowerCase() },
  ]));

  function onWidgetBetsChanged(payload: VirtualSportChangedBets): void {
    for (const bet of payload.added) {
      void addToSlip(bet);
    }
    for (const bet of payload.removed) {
      void removeFromSlip(bet);
    }
  }

  function syncConfig(): void {
    if (!selectedVirtualSport.value) {
      return;
    }

    const extraParameters: Partial<Record<VirtualSportFramedWidgetProperties, string>> = {};

    if (stylable.value) {
      const extraStyleParameter = getSearchParameterValue(VirtualSportFramedWidgetProperties.Style);
      extraParameters[VirtualSportFramedWidgetProperties.Style] = extraStyleParameter
        ? String(extraStyleParameter)
        : undefined;
    }

    postMessageBus?.emit(VirtualSportWidgetPostMessageEvent.syncConfig, {
      ...extraParameters,
      clientId: clientId.value,
      sport: selectedVirtualSport.value,
      url: providerUrl.value,
    });
  }

  function onIFrameMounted(): void {
    const iframeContentWindow = requireContentWindowByIFrameName(iframeName);

    postMessageBus = new PostMessageBus({
      target: iframeContentWindow,
      targetOrigin: '*',
      initiator: VirtualSportWidgetPostMessageBusInitiator,
    });

    postMessageBus.on(VirtualSportWidgetPostMessageEvent.stateChanged, (state: VirtualSportState) => {
      emit.widgetLoaded(state.loaded);
      frame.value?.resizeIframe(state.height);
    });

    postMessageBus.on(VirtualSportWidgetPostMessageEvent.betsChanged, onWidgetBetsChanged);
    postMessageBus.on(VirtualSportWidgetPostMessageEvent.syncConfig, syncConfig);
  }

  function releaseWidget(): void {
    postMessageBus?.dispose();
    emit.widgetLoaded(false);
  }

  watch(selectedVirtualSport, syncConfig);
  watch(selectedBetsMappings, (mappings: SlipMappingKey[]): void => {
    postMessageBus?.emit(VirtualSportWidgetPostMessageEvent.slipSelectedMappingsChanged, { mappings });
  }, { immediate: true });

  return {
    iframeName,
    frame,
    iframeWidgetSrc,
    additionalQueryStringParameters,
    onIFrameMounted,
    releaseWidget,
  };
}
