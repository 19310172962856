import type { Ref } from 'vue';
import type { RouteLocationRaw } from 'vue-router';
import { computed } from 'vue';

import { RouteName } from '@leon-hub/routing-config-names';

import type { SportlineFilterItem } from 'web/src/modules/sportline/components/navigation-filter/types';
import type {
  VirtualSportFilterOption,
  VirtualSportFilterOptions,
  VirtualSportSelectedFilter,
} from 'web/src/modules/sportline/submodules/virtual-sport/types';
import { SportlineNavigationItemCustomStyle } from 'web/src/modules/sportline/enums/navigation';
import { isSelectedVirtualSportFilterOption } from 'web/src/modules/sportline/submodules/virtual-sport/utils';

import { displayConfigToIconConfig } from './utils';

interface UseSportlineVirtualSportFilterProps {
  options: Ref<VirtualSportFilterOptions>;
  filter: Ref<Maybe<VirtualSportSelectedFilter>>;
}

interface UseSportlineVirtualSportFilterEmits {
  changeFilter(to: VirtualSportSelectedFilter): void;
}

interface UseSportlineVirtualSportFilterComposable {
  items: Ref<SportlineFilterItem[]>;
}

export function useSportlineVirtualSportFilter(
  props: UseSportlineVirtualSportFilterProps,
  emits: UseSportlineVirtualSportFilterEmits,
): UseSportlineVirtualSportFilterComposable {
  const {
    options,
    filter,
  } = props;

  function emitChangeFilter(option: VirtualSportFilterOption): void {
    emits.changeFilter({ urlName: option.navigationParameters.urlName });
  }

  const items = computed<SportlineFilterItem[]>(() => (
    options.value.sports.map((option) => {
      const { displayConfig } = option;
      const to: RouteLocationRaw = {
        name: RouteName.SPORTLINE_VIRTUAL_SPORT,
        params: { sport: option.sport.toLowerCase() },
      };
      const isActive = isSelectedVirtualSportFilterOption(option, filter.value);
      const icon = displayConfigToIconConfig(displayConfig);

      return {
        key: option.id,
        icon,
        title: displayConfig?.label || option.sport,
        customStyle: displayConfig?.highlighted
          ? SportlineNavigationItemCustomStyle.Bundesliga
          : undefined,
        isActive,
        to,
        onClick: () => emitChangeFilter(option),
        dataTest: option.sport,
        transitionId: option.sport,
      };
    })
  ));

  return { items };
}
