import type {
  RouteLocationNormalized,
} from 'vue-router';

import {
  assert,
  isOptionalString,
} from '@leon-hub/guards';
import { RouteName } from '@leon-hub/routing-config-names';

import type { AbstractPrefetchResult, AppVueRouter } from '@core/router';
import { useRootNavigationStore } from '@core/navigation';
import { AbstractPrefetch } from '@core/router';

import type { VirtualSportSelectedFilter } from 'web/src/modules/sportline/submodules/virtual-sport/types';
import { useVirtualSportStore } from 'web/src/modules/sportline/submodules/virtual-sport/store';
import { findSelectedVirtualSportFilterOption } from 'web/src/modules/sportline/submodules/virtual-sport/utils';

export default class VirtualSportPagePrefetch extends AbstractPrefetch {
  prefetch(
    router: AppVueRouter,
    to: RouteLocationNormalized,
  ): AbstractPrefetchResult {
    const virtualSportStore = useVirtualSportStore();

    if (!virtualSportStore.isVirtualSportAvailable) {
      return ({ name: RouteName.ERROR_NOT_FOUND_404 });
    }

    const navigationStore = useRootNavigationStore();

    assert(isOptionalString(to.params.sport));

    const filter: VirtualSportSelectedFilter = { urlName: to.params.sport };

    navigationStore.setOnlyFirstRouteContentLoading(RouteName.SPORTLINE_VIRTUAL_SPORT);

    if (filter.urlName) {
      const optionForNavigation = findSelectedVirtualSportFilterOption(virtualSportStore.filterOptions, filter);

      if (!optionForNavigation) {
        return ({ name: RouteName.ERROR_NOT_FOUND_404 });
      }
    }

    virtualSportStore.setListFilter(filter);
    navigationStore.setRouteContentLoaded(RouteName.SPORTLINE_VIRTUAL_SPORT);

    return Promise.resolve();
  }
}
